import React from "react";

// components
import Navbar from "../../components/Navbar";
import SimpleSlider from "../../components/hero";
import FooterSection from "../../components/Footer";
import AboutSection from "../../components/about";
import Services from "../../components/services";
// import FunFact from '../../components/FunFact'
import Pricing from "../../components/Pricing";
// import TeamSection from '../../components/Team'
import BlogSection from "../../components/BlogSection";
// import ContactSection from '../../components/ContactSection'
// import PartnerSlider from '../../components/partner'
import TopBanner from "../../components/TopBanner/topBanner";
import { Helmet } from "react-helmet";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Comprehensive Car Repair Services | Momelizza</title>
        <meta
          name="description"
          content="Momelizza is an all-around car repair solutions provider with modern techniques and tools."
        />
      </Helmet>
      <div className="page-wrapper">
        <TopBanner />
        <Navbar />
        <SimpleSlider />
        <AboutSection />
        <Services />
        {/* <FunFact/> */}
        <Pricing />
        {/* <TeamSection/> */}
        <BlogSection />
        {/* <ContactSection/> */}
        {/* <PartnerSlider/> */}
        <FooterSection />
      </div>
    </>
  );
};

export default Homepage;
