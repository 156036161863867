"use client";

import React, { useEffect, useState } from "react";
import "./topBanner.css";
// import Prop from "./travelbag-banner.jpg";

const TopBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const [active, setActive] = useState("");
  const [urlLink, setUrlLink] = useState("");

  // Fetch banner configuration from the external URL
  useEffect(() => {
    const fetchBannerConfig = async () => {
      try {
        const response = await fetch(
          "/data/bannerConfig.json"
        );
        // console.log("Response:", response); // Log response status and headers
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log("Fetched data:", data);
        setActive(data.selectedOption);
        setUrlLink(data.inputValue);
      } catch (error) {
        console.error("Error fetching banner config:", error);
      }
    };

    fetchBannerConfig();
  }, []);

  // Handle window resize
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // If the banner is not active, do not render it
  if (active !== "active") return null;

  const handleRedirect = () => {
    if (urlLink) {
      window.open(urlLink, "_blank");
    }
  };

  const getPadding = () => {
    if (windowWidth < 768) return "20px 50px";
    if (windowWidth < 991) return "20px 100px";
    return "20px 200px";
  };

  const buttonStyles = {
    background: "#1eb034",
    border: "1px solid #1eb034",
    color: "rgb(255, 255, 255)",
    padding: getPadding(),
    fontFamily: "DM Sans, sans-serif",
    fontOpticalSizing: "auto",
    fontWeight: 600,
    fontSize: "30px",
    fontStyle: "normal",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: isHovered ? "scale(1.1)" : "scale(1)", // Apply scale transformation on hover
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="topbanner_main"
      onClick={handleRedirect}
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // backgroundImage: `url(${Prop})`, // Use url() with the image path
        background:
          "linear-gradient(0deg, rgba(2, 0, 1, 0.9), rgba(2, 0, 1, 0.9))",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div
        className="text_css"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <h2
          style={{
            fontWeight: "400",
            lineHeight: "40px",
            color: "#fff",
            fontSize: "40px",
            textTransform: "uppercase",
            marginBottom: "30px",
          }}
        >
          Please Click Here To Continue
        </h2>

        <button
          style={buttonStyles}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          CLICK TO CONTINUE
        </button>
      </div>
    </div>
  );
};

export default TopBanner;
