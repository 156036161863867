import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bannerConfig from "../../data/bannerConfig.json";
import { useAuth } from "../../AuthContext";
// import { useAuth } from '../../../AuthContext';

const Admin = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const [isInputVisible, setIsInputVisible] = useState(
    bannerConfig.selectedOption === "active"
  );
  const [selectedOption, setSelectedOption] = useState(
    bannerConfig.selectedOption
  );
  const [inputValue, setInputValue] = useState(bannerConfig.inputValue);

  useEffect(() => {
    // Redirect to login if not authenticated
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleFirstCheckboxChange = () => {
    setIsInputVisible(true);
    setSelectedOption("active");
    setInputValue(bannerConfig.inputValue);
  };

  const handleSecondCheckboxChange = () => {
    setIsInputVisible(false);
    setSelectedOption("inactive");
    setInputValue(bannerConfig.inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSave = {
      inputValue,
      selectedOption,
    };

    try {
      const response = await fetch(
        "/update-banner-config",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataToSave),
        }
      );

      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      // const result = await response.json();
      alert(
        "Configuration updated successfully! You can go back to the home page."
      );
    } catch (error) {
      alert("There was an error updating the configuration.");
    }
  };

  const handleLogout = () => {
    logout(); // Call the logout function
    navigate("/login"); // Redirect to login page
  };

  return (
    <>
      <div className="container mt-5">
        <div
          className="row justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div
            className="col-lg-4"
            style={{
              backgroundColor: "#f8f9fa",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h1 className="text-center mb-4">Admin Dashboard</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  checked={selectedOption === "active"}
                  onChange={handleFirstCheckboxChange}
                />
                <label className="form-check-label">Active</label>
              </div>
              {isInputVisible && (
                <div className="form-group mt-3">
                  <label htmlFor="inputField">Input Field:</label>
                  <input
                    id="inputField"
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="form-control"
                  />
                </div>
              )}
              <div className="form-check mt-3">
                <input
                  type="radio"
                  className="form-check-input"
                  checked={selectedOption === "inactive"}
                  onChange={handleSecondCheckboxChange}
                />
                <label className="form-check-label">Inactive</label>
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-4">
                Submit
              </button>
              <button
                onClick={() => navigate("/")}
                className="btn btn-secondary w-100 mt-4"
              >
                Go to Home Page
              </button>
              <button
                onClick={handleLogout}
                className="btn btn-danger w-100 mt-4"
              >
                Logout
              </button>{" "}
              {/* Logout button */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
